.carousel {
    width: 100%;
    background-color: blanchedalmond;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    font-family: Georgia, 'Times New Roman', Times, serif;

    .carousel_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1rem;

      .carousel_container_title {
        background-color: rgb(255, 245, 235);
        display: flex;
        justify-content: center;
        width: 56%;
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        margin: 2rem 0;
        padding: 0rem;
        border-radius: 1rem;

        .carousel_container_title_text {
          margin: 1rem 0;
        }
      }

      .carousel_container_slider {
        width: 80%;
        height: 70vh;
        padding: 0rem;

        @media all and (max-width: 780px){
          height: 50vh;
        }

        .carousel_container_slider_slide {
          height: 100%;
          margin: 0 0.25rem 0 0.25rem;
          display: flex;
          align-items: center;
  
          .carousel_container_slider_slide_image {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }