.services {
    background-color: blanchedalmond;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
  
    .services_container {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      background-color: rgb(255, 245, 235);
      border-radius: 3rem;
      width: 70%;
      height: 12rem;
      font-family: Georgia, 'Times New Roman', Times, serif;
      padding: 1rem 2rem 5rem 2rem;
  
      .services_container_title {
        background-color: rgb(255, 235, 205);
        border-radius: 1rem;
        width: 80%;
        display: flex;
        justify-content: center;
        font-size: 2rem;
        font-weight: bold;
        padding: 1rem;
        margin: 1rem 0;
      }
  
      .services_container_tooltip {
        width: 80%;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        text-align: center;
      }
  
      .services_container_dropdown {
        position: relative;
        background-color: blanchedalmond;
        border-radius: 1.5rem;
        padding: 1rem;
        box-shadow: 0px 0px 3px #666;
        height: 40%;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
  
        .services_container_dropdown_button {
          position: absolute;
          top: 1.75rem;
          background-color: white;
          height: 50%;
          width: 80%;
          border: none;
          box-shadow: 0px 0px 3px #666;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-family: Georgia, 'Times New Roman', Times, serif;
  
          &:hover {
            cursor: pointer;
          }
        }
  
        .services_container_dropdown_list {
          position: absolute;
          top: 1.75rem;
          background-color: white;
          width: 80%;
          padding-top: 0.9rem;
          padding-bottom: 0.9rem;
          box-shadow: 0px 0px 3px #666;
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 1;
          max-height: 15rem;
          overflow-y: scroll;
  
          ::-webkit-scrollbar-track {
            border-radius: 1rem;
          }
  
          ::-webkit-scrollbar-thumb {
            border-radius: 1rem;
          }
  
          .services_container_dropdown_list_option {
            margin: auto;
            margin-top: 0.1rem;
            margin-bottom: 0.1rem;
            text-align: center;
  
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
  
      .services_container_time {
        position: relative;
        background-color: blanchedalmond;
        border-radius: 1.5rem;
        padding: 1rem;
        box-shadow: 0px 0px 3px #666;
        height: 40%;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
  
        .services_container_time_text {
          position: absolute;
          top: 1.75rem;
          background-color: white;
          height: 50%;
          width: 80%;
          box-shadow: 0px 0px 3px #666;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
  
  .hidden {
    display: none !important;
  }