.staff {
    padding: 2rem 0;
    background-color: blanchedalmond;
    display: flex;
    justify-content: center;
  
    .staff_container {
      width: 100%;
      font-family: Georgia, 'Times New Roman', Times, serif;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      background-color: rgb(255, 245, 235);
      padding-bottom: 1rem;
  
      .staff_container_title {
        display: flex;
        justify-content: center;
        width: 56%;
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        margin: 2rem 0;
        padding: 1rem;
        background-color: blanchedalmond;
        border-radius: 1rem;
      }
  
      .staff_container_names {
        width: 56%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
  
        .staff_container_names_one,
        .staff_container_names_two,
        .staff_container_names_three {
          margin-bottom: 2rem;
          border-radius: 1rem;
          width: 7rem;
          box-shadow: 0 0 3px #666;
          background-color: blanchedalmond;
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .staff_container_names_one_image,
          .staff_container_names_two_image {
            margin: 7px;
            border-radius: 1rem;
            height: 5rem;
            width: 5rem;
            background-image: url("../assets/hair-designer.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: white;
          }
  
          .staff_container_names_three_image {
            margin: 7px;
            border-radius: 1rem;
            height: 5rem;
            width: 5rem;
            background-image: url("../assets/nails.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: white;
          }
  
          .staff_container_names_one_name,
          .staff_container_names_two_name,
          .staff_container_names_three_name {
            text-align: center;
            font-size: 1.5rem;
            border-radius: 1rem;
            margin: 1rem 0;
          }
        }
      }
    }
  }