.header {
    background-color: blanchedalmond;
    padding: 1rem;
  
    .header_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Georgia, 'Times New Roman', Times, serif;
  
      .header_container_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 1rem 0;
        margin-bottom: 1rem;
  
        .header_container_top_logo_div {
          display: flex;
          align-items: center;
          margin: 0 1rem;
          height: 3.5rem;
          
          &:hover {
            cursor: pointer;
          }
  
          .header_container_top_logo_div_image {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
  
        .header_container_top_info {
          display: flex;
          flex-wrap: wrap;
  
          .header_container_top_info_phone_div,
          .header_container_top_info_address {
            display: flex;
            align-items: center;
            margin: 0 1rem;
            height: 3.5rem;
            
            &:hover {
              cursor: pointer;
            }
            @media all and (max-width: 780px ){
              display: none;
            }
          }
  
          .header_container_top_info_phone {
            text-decoration: none;
            color: black;
          }
  
          .header_container_top_info_phone_div_image {
            height: 2rem;
            width: 2rem;
            margin-right: 1rem;
          }
  
          .header_container_top_info_address_image {
            height: 2rem;
            width: 2rem;
            margin-right: 1rem;
          }
        }
      }
  
      .header_container_buttons {
        display: flex;
        justify-content: center;
        flex: 20%;
        height: 3.5rem;
        @media all and (max-width: 780px ){
          display: none;
        }

        .header_container_buttons_carousel,
        .header_container_buttons_staff,
        .header_container_buttons_services {
          width: calc(100% / 4.5);
          border: none;
          background-color:blanchedalmond;
          font-family: Georgia, 'Times New Roman', Times, serif;
          height: 100%;
          font-weight: 500;
          transition: all 0.20s ease;
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          text-decoration: none;
          
          &:hover {
            cursor: pointer;
          }
  
          &:active {
            background-color: blanchedalmond;
          }
        }
  
        .header_container_buttons_carousel {
          border-radius: 1rem 0 0 1rem;
        }
  
        .header_container_buttons_services {
          border-radius: 0 1rem 1rem 0;
        }
      }
    }
  }