.footer {
    width: 100%;
  
    .footer_container {
      margin: 0;
      width: 100%;
      height: 50vh;
      padding: 1rem;
      background-color: blanchedalmond;
      font-family: Georgia, 'Times New Roman', Times, serif;
      box-sizing: border-box;
      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 30rem;
  
      .footer_container_title {
        border-bottom: solid black 1px;
        margin-bottom: 1rem;
      }
  
      .footer_container_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
  
        .footer_container_content_hours {
          margin-left: 0.7rem;
  
          .footer_container_content_hours_title {
            margin-bottom: 0;
            margin-top: 0.5rem;
          }
  
          .footer_container_content_hours_times {
            margin-top: 0;
            display: flex;
  
            .footer_container_content_hours_times_days {
              margin-right: 1rem;
            }
          }
        }
  
        .footer_container_content_phone_div,
        .footer_container_content_address,
        .footer_container_content_email_div {
          display: flex;
          align-items: center;
          margin-left: 0.05rem;
          width: 15rem;
        }
  
        .footer_container_content_address,
        .footer_container_content_phone {
          &:hover {
            cursor: pointer;
          }
        }
  
        .footer_container_content_email,
        .footer_container_content_phone {
          color: black;
          text-decoration: none;
        }
  
        .footer_container_content_phone_div_image,
        .footer_container_content_address_image,
        .footer_container_content_email_div_image {
          width: 1.2rem;
          height: 1.2rem;
          margin: 0.5rem;
        }
      }
    }
  }