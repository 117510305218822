body, html {
    margin: 0;
  }
  
  .hero {
    width: 100%;
    height: 50%;
  
    .hero_container {
      background-image: url("../assets/hero2.png");
      background-size: auto;
      background-position: center;
      background-repeat: repeat;
      height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: Georgia, 'Times New Roman', Times, serif;
  
      .hero_container_plate {
        background-color: blanchedalmond;
        border-radius: 1rem;
        opacity: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 1rem;
        width: 80%;
        max-width: 668px;
  
        .hero_container_plate_title,
        .hero_container_plate_subtitle,
        .hero_container_plate_description,
        .hero_container_plate_call_button,
        .hero_container_plate_phone {
          margin-right: 1.5rem;
          margin-left: 1.5rem;
        }
  
        .hero_container_plate_title {
          font-size: 3rem;
          margin-bottom: 0;
        }
  
        .hero_container_plate_subtitle {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 1rem;
        }
  
        .hero_container_plate_description {
          font-size: 1.5rem;
          text-align: right;
        }
  
        .hero_container_plate_call_button {
          padding: 1rem;
          margin-bottom: 1.5rem;
          margin-top: 1.5rem;
          background-color: antiquewhite;
          font-size: 1rem;
          font-family: Georgia, 'Times New Roman', Times, serif;
          border-radius: 3rem;
          border: solid black 4px;
          box-shadow: 0.25rem 0.25rem 1rem black;
          transition: all 0.25s ease;
  
          &:hover {
            background-color: rgb(255, 245, 225);
            box-shadow: 1rem 1rem 2rem black;
            transform: translateY(-0.5rem);
            cursor: pointer;
          }
  
          &:active {
            background-color: black;
            color: rgb(255, 245, 225);
            border: solid rgb(255, 245, 225) 4px;
            box-shadow: 1rem 1rem 2rem black;
            transform: translateY(-0.2rem);
          }
        }
  
        .hero_container_plate_phone {
          color: black;
          text-decoration: none;
        }
      }
    }
  }